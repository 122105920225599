<template>
  <div v-if="dataInfo" id="serviceInfo">
    <div class="container">
      <div class="service-title van-hairline--bottom">
        <template v-if="status == 1"> 
          <div class="service-title-wrap">
            <span class="iconfont icon-yes"></span>
            <!-- 申请已受理，售后专员正在处理中 -->
            <span>{{language.l_267}}</span>
          </div>
        </template>

        <template v-else-if="status == 2"> 
          <div class="service-title-wrap">
            <span class="iconfont icon-yes service-icon"></span>
            <!-- 退款成功，请耐心等待回款 -->
            <span>{{language.l_268}}</span>
          </div>
          <!-- 尊敬的客户，感谢您前来购物。我们会为您打造优质的购物体验 -->
          <p class="service-test">{{language.l_269}}</p>
          <!-- 退款金额 -->
          <p class="service-price"><span>{{language.l_270}}</span><span>${{dataInfo.amount}}</span></p>
        </template>

        <template v-else-if="status == 3"> 
          <div class="service-title-wrap">
            <span class="iconfont icon-close2"></span>
            <!-- 商家拒绝您的申请，请及时处理 -->
            <span>{{language.l_271}}</span>
          </div>
          <!-- 尊敬的用户，您的售后已被拒绝。如果您有其他退款诉求请联系客服 -->
          <p class="service-test">{{language.l_272}}</p>
        </template>

        <template v-else-if="status == 4"> 
          <div class="service-title-wrap">
            <span class="iconfont icon-close2"></span>
            <!-- 您已主动撤销此次售后申请 -->
            <span>{{language.l_273}}</span>
          </div>
        </template>

        <template v-else> 
          <div class="service-title-wrap">
            <span class="iconfont icon-yes"></span>
            <!-- 申请已提交，请等待售后专员处理 -->
            <span>{{language.l_279}}</span>
          </div>
        </template>
      </div>

      <template v-if="status != 2 || status != 3 || status != 4"> 
        <div class="service-wrams">
          <div class="service-wram">
            <span class="iconfont icon-dian"></span>
            <!-- 申请通过：款项将原路退还至您的账户 -->
            <span>{{language.l_280}}</span>
          </div>
          <div class="service-wram">
            <span class="iconfont icon-dian"></span>
            <!-- 处理时长：专员会在一周之内处理完成 -->
            <span>{{language.l_281}}</span>
          </div>
        </div>
      </template>

      <div class="service-btn">
        <a class="van-hairline--surround" :href="'tel://' + value">联系客服</a>
        <!-- 撤销申请 -->
        <p v-if="status != 2 && status != 3 && status != 4" @click="cancelService" class="van-hairline--surround">{{language.l_282}}</p>
      </div>
    </div>

    <div class="goods-card van-hairline--top">
      <van-card v-for="(item, index) in dataInfo.goods" :key="index">
        <template v-slot:thumb>
          <van-image fit="contain" :src="item.goods_pic" />
        </template>
        <template v-slot:title>
          <div class="card-wrap">
            <div class="card-left">
              <p class="card-title">{{item.goods_title}}</p>
            </div>
            <div class="card-right">
              <div class="card-right-wrap">
                <p class="card-nowrap">${{item.sale_price}}</p>
              </div>
              <p class="goodsNum">x {{item.product_number}}</p>
            </div>
            <p class="goodsInfo van-ellipsis">{{item.goods_title == item.product_name ? '' : item.product_name}}</p>
          </div>
        </template>
      </van-card>
      <!-- 售后金额： -->
      <van-cell :title="language.l_283" :value="dataInfo.amount" />
      <!-- 售后类型： -->
      <van-cell :title="language.l_284" :value="dataInfo.type_text" />
      <!-- 货物状态： -->
      <van-cell :title="language.l_285" :value="dataInfo.package_status_text" />
      <!-- 申请原因： -->
      <van-cell :title="language.l_286" :value="dataInfo.reason_text" />
      <!-- 退款编号： -->
      <van-cell :title="language.l_287" :value="dataInfo.after_sale_no" />
      <!-- 申请时间： -->
      <van-cell :title="language.l_288" :value="dataInfo.create_time" />
    </div>

    <!-- 步骤条 -->
    <div class="service-steps">
      <van-steps direction="vertical" :active="0" active-color="#FB2C37">
        <van-step v-for="(item, index) in dataInfo.logs" :key="index">
          <h3 class="steps-title">{{item.text}}</h3>
          <p class="steps-content">{{item.desc}}</p>
          <p class="steps-time">{{item.time}}</p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import {serviceInfo, cancelService, getBalance} from '@api';

export default {
  data() {
    return {
      dataInfo: null,
      isUpdate: false, // 是否更改了订单状态 - 返回后是否刷新订单列表页
      language: this.$store.getters.language,
      value: ''
    }
  },
  created() {
    const {isUpdate} = this.$route.query;
    if(typeof isUpdate != 'undefined') {this.isUpdate = isUpdate}
    this.getData();
    getBalance().then(res => {
      if(res.data.hasOwnProperty('mobile')) {this.value = res.data.mobile.value}
    })
  },
  methods: {
    cancelService() { // 撤销申请
      this.$dialog.confirm({
        message: this.language.l_289,  //确定要撤销吗，撤销之后不能再次哟
        cancelButtonText: this.language.l_22,  //我再想想
        confirmButtonText: this.language.l_290  //撤销
      }).then(() => {
        const {id: after_sale_id} = this.$route.params;
        cancelService(after_sale_id).then(() => {
          this.$toast.success(this.language.l_291);  //撤销成功
          this.getData();
        })
      }).catch(() => this.$toast.success(this.language.l_315))  //取消撤销
    },
    getData() {
      const {id} = this.$route.params;
      serviceInfo(id).then(res => this.dataInfo = res.data).catch(err => {
        this.$toast(err)
        this.$router.go(-1)
      })
    }
  },
  computed: {
    status() {return this.dataInfo.status}
  },
  beforeRouteLeave (to, from, next) {
    if(to.name == 'OrderList') {to.meta.isUseCache = !this.isUpdate}
    next();
  }
}
</script>

<style lang="less" scoped>
#serviceInfo {
  .container {
    box-sizing: border-box;
    padding: 0 10px 10px 10px;
    background-color: #fff;
    .service-title {
      font-size: 16px;
      padding: 10px 0;
      span {
        vertical-align: middle;
      }
      .service-title-wrap {
        display: flex;
        align-items: center;
        font-weight: bold;
        .iconfont {
          display: inline-block;
          width: 25px;
          height: 25px;
          line-height: 25px;
          font-size: 20px;
          text-align: center;
          color: #fff;
          border-radius: 50%;
          background-color: #F4BD43;
          margin-right: 8px;
          align-self: flex-start;
          &.icon-i {
            line-height: 24px;
          }
          &.icon-close2 {
            background-color: #F83737;
            font-size: 18px;
          }
          &.service-icon {
            background-color: #3CCC37;
          }
        }
      }
      .service-test {
        box-sizing: border-box;
        padding: 9px;
        font-size: 12px;
        background-color: #F3ECDD;
        margin-top: 10px;
      }
      .service-address {
        box-sizing: border-box;
        padding: 10px 10px 0 10px;
        font-size: 12px;
        margin-top: 10px;
        line-height: 22px;
      }
      .service-price {
        font-size: 14px;
        margin-top: 10px;
        span {
          &:nth-of-type(1) {
            margin-right: 30px;
          }
          &:nth-of-type(2) {
            color: #E6505F;
            font-weight: bold;
          }
        }
      }
    }

    .service-wrams {
      box-sizing: border-box;
      padding: 10px 15px 0;
      font-size: 12px;
      color: #999999;
      .service-wram {
        margin-bottom: 10px;
        span {
          vertical-align: middle;
        }
        p {
          display: inline-block;
          vertical-align: middle;
        }
        .icon-dian {
          color: #999999;
          font-size: 18px;
        }
        .wram-date {
          color: red;
          vertical-align: top;
          margin: 0 2px;
        }
      }
    }

    .service-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      a,
      p {
        width: 70px;
        height: 30px;
        margin-left: 20px;
        line-height: 30px;
        text-align: center;
        font-size: 12px;
        &::after {
          border-radius: 10px;
        }
        &.service-danger {
          color: red;
          &::after {
            border-color: red;
          }
        }
      }
      a {
        color: #000;
      }
    }
  }
  .goods-card {
    position: relative;
    box-sizing: border-box;
    padding: 0 10px;
    margin: 10px 0;
    background-color: #fff;
    .van-card {
      box-sizing: border-box;
      padding: 10px;
      border-radius: 10px;
      background-color: #fff;
      .van-card__thumb {
        box-shadow: 1px 0px 2px rgba(0,0,0,.1);
        background-color: #fff;
        border-radius: 8px;
        .van-image {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .card-wrap {
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .card-left {
          .card-title {
            line-height: 1.5;
          }
        }
        .card-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          .card-nowrap {
            white-space: nowrap;
            color: red;
            font-weight: bold;
          }
          .goodsNum {
            color: #999999;
          }
        }
        .goodsInfo {
          text-align: right;
          margin-top: 10px;
          color: #999999;
          line-height: 1.5;
        }
      }
    }
    .van-cell {
      .van-cell__value {
        flex: 3;
        -webkit-flex:3;
        text-align: left;
      }
    }
  }
  .service-steps {
    .steps-title {
      font-size: 14px;
    }
    .steps-content {
      font-size: 12px;
      margin-top: 5px;
    }
    .steps-time {
      font-size: 12px;
      margin-top: 5px;
    }
    .van-step__icon--active {
      font-size: 14px;
    }
    /deep/ .van-step__circle {
      width: 8px;
      height: 8px;
    }
    .van-step--process {
      /deep/ .van-icon-checked {
        font-size: 14px;
      }
    }
  }

  .van-popup {
    border-radius: 10px 10px 0 0;
    .model-wrap {
      box-sizing: border-box;
      padding: 10px;
      .model-header {
        font-size: 16px;
        text-align: center;
        box-sizing: border-box;
        padding-bottom: 10px;
      }
      .van-cell {
        padding: 10px 0;
        &::after {
          left: 0;
        }
      }
      .model-btn {
        width: 350px;
        height: 40px;
        margin: 50px auto 0;
        font-size: 14px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background-image: @grad_left;
      }
    }
  }
}
</style>